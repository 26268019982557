import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const GameList = ({ Games, deleteGame, updateGame }) => {

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Identificador</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Games.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.Identificador}</TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell>{row.Price}</TableCell>
                <TableCell>{row.Category}</TableCell>
                <TableCell>
                  <Button onClick={() => updateGame(row.Identificador)} color="primary">
                    Edit
                  </Button>
                  <Button onClick={() => deleteGame(row.Identificador)} color="secondary">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
export default GameList;
