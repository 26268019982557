import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
class GameFormInsert extends React.Component {
  
  state = {
      Identificador: '',
      Name: '',
      Price: '',
      Category: '',
    };

    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };


    handleSubmit = (e) => {
      e.preventDefault();
    axios.headers =  {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*'
    };
    axios.mode = 'no-cors';


      axios.post('https://l5w3ph3lad.execute-api.sa-east-1.amazonaws.com/Production/api/Games', this.state)
      .then(response => {
        console.error('There was n error adding the Game', response);
      })
      .catch(error => {
        console.error('There was an error adding the Game', error);
      });
  };

  render() {
    return (
       <Paper style={{ padding: 16 }}>
          <Typography variant="h6">New Post</Typography>
      <form  onSubmit={this.handleSubmit}>
  
      <TextField
              label="Identificador"
              name="Identificador"
              fullWidth
              
              rows={1}
              margin="normal"
              value={this.state.Identificador}
              onChange={this.handleChange}
            />
  
      <TextField
              label="Name"
              name="Name"
              fullWidth
              
              rows={1}
              margin="normal"
              value={this.state.Name}
              onChange={this.handleChange}
            />
                    <TextField
              label="Price"
              name="Price"
              fullWidth
              
              rows={1}
              margin="normal"
              value={this.state.Price}
              onChange={this.handleChange}
            />
                    <TextField
              label="Category"
              name="Category"
              fullWidth
              
              rows={1}
              margin="normal"
              value={this.state.Category}
              onChange={this.handleChange}
            />
        <Button type="submit" color="primary" variant="contained">Add Game</Button>
     </form >
        </Paper>
      );
    }
  }
  
  export default GameFormInsert;