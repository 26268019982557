import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GameList from './GameList';
import GameFormInsert from './GameFormInsert';

const App = () => {
  const [Games, setGames] = useState([]);
  const [Game, setGame] = useState({});
  
  const loadGames  = () => {
    axios('https://l5w3ph3lad.execute-api.sa-east-1.amazonaws.com/Production/api/Games', {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
      }
    })
      .then(response => {
        setGames(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching data', error);
      });
  };

  useEffect(() => {
    loadGames();

  }, []);

 

  const deleteGame = (Identificador) => {
    axios.headers =   {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
    };
    axios.mode = 'no-cors';

    axios.delete(`https://l5w3ph3lad.execute-api.sa-east-1.amazonaws.com/Production/api/Games/${Identificador}`)
      .then(() => {
        
      })
      .catch(error => {
        console.error('There was an error deleting the Game', error);
      });
      loadGames();
  };

  const updateGame = (Identificador) => {
    axios.headers =   {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
    };
    axios.mode = 'no-cors';

    axios.get(`https://l5w3ph3lad.execute-api.sa-east-1.amazonaws.com/Production/api/Games/${Identificador}`)
      .then(response => {
        setGame({
            Identificador: response.data.Identificador,
            Name: response.data.Name,
            Price: response.data.Price,
            Category: response.data.Category
          });
      })
      .catch(error => {
        console.error('There was an error updating the Game', error);
      });
    
  };

  return (
    <div>
      <h1>Game Manager</h1>
      
      <GameFormInsert Game={Game}  />
      <GameList Games={Games} deleteGame={deleteGame} updateGame={updateGame} />
    </div>
  );
};

export default App;
